import axios from 'axios';

const state = {
    list: null
};

const getters = {};

const actions = {
    async searchAccount({rootGetters}, form) {
        return await axios.get('campaign/account-search', {params: form})
    },

    async getAccountCount({rootGetters}, form) {
        return await axios.get('campaign/account-count', {params: form})
    },

    async getAccounts({rootGetters}, form) {
        return await axios.get('account/list', {params: form})
    },

    async getAccountReport({rootGetters}, form){
        return await axios.get('account/report',{params: form})
    },

    async createAccount({rootGetters}, form) {
        return await axios.post('account/create', form)
    },

    async updateAccount({rootGetters}, form) {
        return await axios.post('account/update', form)
    },

    async detailAccount({rootGetters}, form) {
       return await axios.get('account/detail', {params: form})
    },

    async deleteAccount({rootGetters}, form) {
        return await axios.post('account/delete', form)
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
};
